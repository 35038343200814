import React from "react";
import { Page, Contact, Section } from "../components";
import { css } from "@emotion/css";

const paragraph = css`
  font-family: Lato, sans-serif;
  font-size: 22px;
  letter-spacing: 1px;
  line-height: 1.6;
  text-align: center;
`;

const ContactPage = () => {
  return (
    <Page
      tabTitle="Nous contacter"
      pageTitle="Nous contacter"
      id="formulaire-de-contact"
    >
      <Section>
        <p className={paragraph}>
          Vous souhaitez nous contacter pour obtenir des renseignements ou
          établir un devis ?<br />
          Complétez le formulaire ci-dessous, nous ferons notre possible pour
          vous répondre au plus vite.
        </p>
      </Section>
      <Contact />
    </Page>
  );
};

export default ContactPage;
